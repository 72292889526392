<template>
  <div>
    <v-data-table :headers="headers.filter(header => header.visibleFor === role || role === 'admin')" flat
                  :items="records.data"
                  :items-per-page="10"
                  hide-default-footer>
      <template v-slot:item.customer="{item}">
        <h3 class="pointer">{{item.contract.customer.full_name }}</h3>
      </template>
      <template v-slot:item.case="{item}">
          № {{item.contract_job_id}}-{{item.rotation_number}}
      </template>
      <template v-slot:item.from="{item}">
        <span>{{item.start_date | moment("DD MMM YY") }}</span>
      </template>
      <template v-slot:item.to="{item}">
        <span>{{item.end_date | moment("DD MMM YY") }}</span>
      </template>
      <template v-slot:item.link="{item}">
        <v-btn @click="$router.push('/contracts/'+item.contract_id)">
          View
        </v-btn>
      </template>
    </v-data-table>
    <v-pagination
        v-model="page"
        @input="paginationChange"
        :length="records.last_page"
        :total-visible="6"
    ></v-pagination>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import rotations from "@/api/modules/contractRotations"
export default {
  data: function () {
    return {
      page: 1,
      headers: [
        {text: 'Customer', value: 'customer', visibleFor: ['admin', 'dispo'], sortable: false},
        {text: 'Case #', value: 'case', visibleFor: ['admin', 'dispo'], sortable: false},
        {text: 'From', value: 'from', visibleFor: ['admin', 'dispo'], sortable: false},
        {text: 'To', value: 'to', visibleFor: ['admin', 'dispo'], sortable: false},
        {text: 'Link to Contract', value: 'link', visibleFor: ['admin', 'dispo'], sortable: false},
      ],
      records: [],
    }
  },
  created() {
    this.fetchRecords();
  },
  computed: {
    ...mapGetters('auth', ['role']),
  },
  methods: {
    fetchRecords() {
      rotations.fetchRotations({status: 'draft', page: this.page})
          .then((res) => {
            this.records = res.data
          })
    },
    paginationChange: function () {
      this.fetchRecords();
    },
  }
}
</script>